ScrollTrigger.refresh()

function initVrTeaser() {

  gsap.set(".vr-teaser", { scale: 0.96 });

  // timeline
  let SplitTextvrHeadline = new SplitText('.vrHeadline', { type: 'words,chars' });
  let linesvrHeadline = SplitTextvrHeadline.chars;

  const vrTeaser = gsap.timeline({
    scrollTrigger: {
      trigger: ".vr-teaser",
      start: "center center",
      end: "=+1000",
      scrub: true,
      pin: true,
      pinType: ScrollTrigger.isTouch ? "fixed" : "transform"
    }
  })

  vrTeaser.to(".vr-teaser", {
    scale: 1,
    invalidateOnRefresh: true,
    transformOrigin: 'center'
  })

  vrTeaser.from(linesvrHeadline, {
    duration: 0.8,
    opacity: 0,
    y: 10,
    ease: "circ.out",
    stagger: 0.02
  })
  
  vrTeaser.from(".vrBtn", {
    opacity: 0,
    duration: 0.8,
    yPercent: 100,
  })
}
