// steps

function initStepsAnimation() {
  gsap.set(".illu-step", { yPercent: 30 });

  gsap.to(".illu-step-1", {
    yPercent: 0,
    ease: "Power1.easeOut",
    scrollTrigger: {
      trigger: ".illu-step-1",
      start: "top bottom",
      end: "+=250",
      scrub: 1
    },
  });

  gsap.to(".illu-step-2", {
    yPercent: 0,
    ease: "Power1.easeOut",
    scrollTrigger: {
      trigger: ".illu-step-2",
      start: "top bottom",
      end: "+=250",
      scrub: 1
    },
  });

  gsap.to(".illu-step-3", {
    yPercent: 0,
    ease: "Power1.easeOut",
    scrollTrigger: {
      trigger: ".illu-step-3",
      start: "top bottom",
      end: "+=250",
      scrub: 1
    },
  });

}