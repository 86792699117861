var windowWidth = window.innerWidth;

/* navigation */
var activeState = false;
var navTl = null;

if (windowWidth < 1400) {
  if (navTl == null)
    navTl = createNavTimeline();
} else {
  if (navTl) {
    navTl = null;
  }
}



addEventListener('resize', (event) => {
  var nav = document.querySelector('nav');
  var style = window.getComputedStyle(nav);
  var top = style.getPropertyValue('top');
  targetHeight = 'calc(100vh - 2 * ' + top + ')';
  if (navTl) {
    navTl.invalidate();

    if (navTl.time() == navTl.totalDuration()) {
      nav.style.height = targetHeight;
    }
  }
});

function createNavTimeline() {
  var nav = document.querySelector('nav');
  var style = window.getComputedStyle(nav);
  var top = style.getPropertyValue('top');
  var targetHeight = 'calc(100vh - 2 * ' + top + ')';

  var navTl = gsap.timeline({
    onComplete: function () { smoother.refresh() }
  }).pause();
  navTl.to('.b1', {
    y: '-=5',
    duration: 0.6
  })
  navTl.to('.b2', {
    y: '+=5',
    duration: 0.6
  }, '-=0.6')
  navTl.to('.b1', {
    rotation: 45,
    transformOrigin: '50% 50%',
    duration: 0.8
  })
  navTl.to('.b2', {
    rotation: -45,
    transformOrigin: '50% 50%',
    duration: 0.8
  }, '-=0.8')
  navTl.fromTo('.nav-menu .wrapper', {
    y: '-100%'
  }, {
    y: 0
  })
  navTl.to(nav,
    {
      backgroundColor: 'rgba(237,231,222,1)',
      height: targetHeight,
      y: 0
    },
  );
  navTl.fromTo('.nav-menu .stagger',
    {
      yPercent: 100,
      opacity: 0,
      autoAlpha: 0
    },
    {
      yPercent: 0,
      opacity: 1,
      stagger: 0.2,
      ease: 'Power1.easeOut',
      autoAlpha: 1
    }
  );

  return navTl
}


function setupMobileNavAnimation() {
  /* navigation hamburger menu */

  if (!document.querySelector('.nav-menu')) { return };
  const hamburger = document.querySelector('.hamburger');
  const navMenu = document.querySelector('.nav-menu');

  hamburger.addEventListener('click', function () {
    resetMobileNav();
  });
}

function resetMobileNav() {
  const hamburger = document.querySelector('.hamburger');
  const navMenu = document.querySelector('.nav-menu');

  if (activeState) {

    navTl.reverse().duration(0.5).then(function () {
      hamburger.classList.toggle('active');
      navMenu.classList.toggle('active');
      activeState = false;
    });

    smoother.paused(false);

  } else {

    navTl.play(0).delay(0.8).duration(1);
    hamburger.classList.toggle('active');
    navMenu.classList.toggle('active');

    smoother.paused(true);
    activeState = true;
  }

}



addEventListener('resize', (event) => {
  windowWidth = window.innerWidth;
  if (windowWidth > 1400) {
    if (navTl != null) {
      const hamburger = document.querySelector('.hamburger');
      const navMenu = document.querySelector('.nav-menu');

      document.querySelector('nav').removeAttribute('style');
      document.querySelectorAll('.stagger').forEach(element => element.removeAttribute('style'));
      hamburger.classList.remove('active');
      navMenu.classList.remove('active');
      activeState = false;
      smoother.paused(false);
      showItemsDesktop();
      navTl = null;
    }
  } else {
    if (navTl == null) {
      navTl = createNavTimeline();
    }
  }
});

function showItemsDesktop() {
  gsap.to('.nav-menu .stagger', {
    opacity: 1
  });
  var navWrapper = document.querySelector('.nav-menu .wrapper');
  navWrapper.style.transform = null;
}