function initContactForm() {
  if (document.getElementById("contact-form") == null) { return };

  var contactForm = document.getElementById("contact-form");
  var submitBtn = contactForm.querySelector("#submit");

  formFields = contactForm.querySelectorAll("input, textarea");

  formFields.forEach(field => {
    field.addEventListener("input", function () {

      if (checkAllFilledOut()) {
        submitBtn.disabled = false;
      } else {
        submitBtn.disabled = true;
      }

    })
  });

  contactForm.addEventListener("submit", function (event) {
    event.preventDefault(); // Prevent form submission

    // Get form data
    var firstname = document.getElementById("firstname").value;
    var lastname = document.getElementById("lastname").value;
    var email = document.getElementById("email").value;
    var phone = document.getElementById("phone").value;

    if (document.getElementById("message")) {
      var message = document.getElementById("message").value;
      var choices = [];
    } else {
      var message = '';
      const questions = document.querySelectorAll('.question');
      var choices = [];

      var i = 0;
      questions.forEach((q) => {
        if (!q.querySelector('.checked')) return;
        var selected = q.querySelector('.checked');

        index_question = Array.from(q.parentElement.children).indexOf(q);
        index_answer = selected.getAttribute("data-index");
        choices.push(index_question.toString() + index_answer.toString());

        i++;
      });
      console.log(choices);
    }

    // Create and send AJAX request
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "mail/sendmail.php", true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // Request completed and response received
        console.log(xhr.responseText);
        if (ScrollSmoother.get()) {
          smoother.scrollTo("#contact-form");
        }
        if (slider) {
          document.querySelector('#filled-out').style.display = "none";
          document.querySelector('#angebot-header').style.display = "none";
          setTimeout(() => {
            slider.updateAutoHeight();
          }, 2000);
        }
        showSuccess();
      }
    };

    xhr.send(
      "firstname=" + encodeURIComponent(firstname)
      + "&lastname=" + encodeURIComponent(lastname)
      + "&email=" + encodeURIComponent(email)
      + "&phone=" + encodeURIComponent(phone)
      + "&message=" + encodeURIComponent(message)
      + "&choices=" + choices.toString()
    );

  });
}

function showSuccess() {
  var form = document.getElementById("contact-form");
  var success = document.getElementById("success");
  var scrollToTarget = success.parentElement;
  gsap.to(window, { duration: 2, scrollTo: { y: scrollToTarget, offsetY: 250 } });

  gsap.to(form, 1, {
    opacity: 0,
    height: 0,
    display: "none",
  });
  gsap.to(success, {
    opacity: 1,
    delay: 1.5,
    height: "100%"
  });
}

function animateHeight(container, content) {
  const contentHeight = content.scrollHeight;

  container.style.maxHeight = contentHeight + 'px';
  content.classList.add('animate-height');

  setTimeout(() => {
    container.style.maxHeight = null;
  }, 10);
}

function checkAllFilledOut() {
  var fields = document.querySelectorAll("#contact-form input:not(#website-url), #contact-form textarea");

  const arrayFromNodeList = Array.from(fields);
  return arrayFromNodeList.every(element => element.value.trim() !== '');
}