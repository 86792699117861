// Test JavaScript, to ensure Babel is working.

gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(ScrollSmoother, ScrollTrigger, SplitText, ScrollToPlugin);

initSmoothScroller();
var smoother = ScrollSmoother.get();
smoother.refresh();


// wait until DOM is ready (html and svg markup)
document.addEventListener("DOMContentLoaded", function (event) {

    // wait until window is loaded (images, external JS, external stylesheets, fonts, links, and other media assets)
    window.addEventListener("load", function (event) {


        // makes sure it runs after last render tick
        window.requestAnimationFrame(function () {
            //initPageTransition();
            initAnchorLinks();
            initAccordion();
            initContactForm();
            initGenerator();
            initKitchenColorSwitch();
            setupMobileNavAnimation();
            initStepsAnimation();
            initFurnitureSwiper();
            initVrHeadset();
            initVrTeaser();
            initLazyLoad();
            initRibbon();
        });
    });
});

function main() {
 

}