var slider;

function initGenerator() {

    if (!document.querySelector('#generator')) return;

    initGeneratorSwiper();
    initSelectAnswer();
    initBackButton();

    window.addEventListener("resize", function () {
        slider.updateSize();
    });

}

function initSelectAnswer() {
    var questions = document.querySelectorAll("#generator .question");
    var progressBar = document.querySelector('#progressBar');
    var progressCounter = document.querySelector('#progressCounter');
    var progressMax = document.querySelector('#progressMax');

    progressMax = questions.length;
    progressBar.style.width = 100 / parseInt(document.querySelector('#progressMax').innerText) + "%";

    questions.forEach(question => {
        var answers = question.querySelectorAll(".answer");
        var container = document.querySelector('#generator');

        answers.forEach(answer => {
            answer.addEventListener("click", function () {
                var nextQuestion;

                if (container.classList.contains('animating')) {
                    return
                } else {
                    container.classList.add('animating');
                }

                if (question.querySelector('.answer.checked')) {
                    question.querySelector('.answer.checked').classList.remove("checked");
                }

                answer.classList.add("checked");

                if (answer.getAttribute("data-next") != "") {
                    console.log(answer.getAttribute("data-next"));
                    nextQuestion = parseInt(answer.getAttribute("data-next"));
                } else {
                    nextQuestion = parseInt(question.id) + 1;
                }

                setTimeout(() => {
                    console.log(nextQuestion);
                    slider.slideTo(nextQuestion);
                    gsap.to(window, { duration: 0.25, scrollTo: 0 });
                    if (progressCounter.innerHTML < parseInt(document.querySelector('#progressMax').innerText)) {
                        progressCounter.innerHTML = parseInt(progressCounter.innerHTML) + 1;
                        progressBar.style.width = updateProgressBar(parseInt(progressCounter.innerHTML));
                    }
                    container.classList.remove('animating');
                }, 1300);
            });
        });

    });

}

function initGeneratorSwiper() {
    slider = new Swiper('.swiper-kitchen-generator', {
        direction: 'horizontal',
        loop: false,
        centeredSlides: true,
        watchSlidesProgress: true,
        // width: document.querySelector("#generator .container .wrapper").offsetWidth,
        autoHeight: true,
        breakpointsBase: 'container',
        resizeObserver: true,
        centeredSlidesBounds: true,
        preventClicks: false,
        preventClicksPropagation: false,
        spaceBetween: 300,
        allowTouchMove: false,
        autoHeight: true,
        slidesPerView: 1,
        updateOnWindowResize: true,
        // fadeEffect: {
        //     crossFade: true
        //   },
    });

    window.addEventListener('resize', function () {
        // slider.width = document.querySelector("#generator .container .wrapper").offsetWidth;
        slider.update();
    })
}

function initBackButton() {
    var backBtns = document.querySelectorAll('.back');
    var progressBar = document.querySelector('#progressBar');


    backBtns.forEach(e => {
        e.addEventListener('click', function () {
            var currentSlide = slider.slides[slider.activeIndex];

            if (slider.activeIndex == 0) return;

            if (currentSlide.querySelector('.checked')) {
                currentSlide.querySelector('.checked').classList.remove('checked');
            }

            previous = findlastCheked(currentSlide);
            slider.slideTo(parseInt(previous.id));

            progressCounter.innerHTML = parseInt(progressCounter.innerHTML) - 1;
            progressBar.style.width = updateProgressBar(parseInt(progressCounter.innerHTML));

        })

    });
}

function findlastCheked(element) {
    let prevSibling = element.previousElementSibling;

    while (prevSibling) {
        if (prevSibling.querySelector('.checked')) {
            return prevSibling;
        }
        prevSibling = prevSibling.previousElementSibling;
    }

    return null; // If no matching sibling is found
}

function updateProgressBar(index) {
    var progressMax = document.querySelector('#progressMax');

    var percentile = 100 / parseInt(progressMax.innerText);
    return percentile * parseInt(index) + '%';
}