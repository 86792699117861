function initSmoothScroller() {
  // scroll smoother 
  const smoother = ScrollSmoother.create({
    wrapper: '#smooth-wrapper',
    content: '#smooth-content',
    smooth: 1,
    smoothTouch: false,
    ignoreMobileResize: true,
    effects: true,
    preventDefault: true
  });
  return smoother;
}