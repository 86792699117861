// vr headset
function initVrHeadset() {
  let mm = gsap.matchMedia();

  mm.add("(min-width: 1200px)", () => {

    gsap.to(".headset-front", {
      yPercent: -60,
      ease: "none",
      scrollTrigger: {
        trigger: ".headset-front",
        start: "top 60%",
        scrub: 4
      },
    });

    gsap.to(".controller", {
      yPercent: -20,
      ease: "none",
      scrollTrigger: {
        trigger: ".controller",
        start: "top bottom",
        scrub: 6
      },
    });

    gsap.to(".headset-back", {
      yPercent: 30,
      ease: "none",
      scrollTrigger: {
        trigger: ".headset-back",
        start: "top 55%",
        scrub: 8
      },
    });
  })
}