
function initKitchenColorSwitch() {
    if (!document.querySelector(".kitchen-switch")) return;

    var buttons = document.querySelectorAll(".kitchen-switch .color-buttons button");
    var imagesContainer = document.querySelector(".kitchen-switch .images");
    var currentImage = imagesContainer.querySelector("img.visible");
    
    buttons.forEach(b => {
        b.addEventListener("click", function() {
            var targetColor = b.classList[0]; // Get target color
            var newImage = imagesContainer.querySelector('img.' + targetColor); // Get target image
            var oldImage = currentImage; // Set current image as old image
            
            if (newImage == oldImage || imagesContainer.classList.contains('transitioning')) return;

            imagesContainer.classList.add('transitioning');

            newImage.classList.add('visible');
            newImage.style.zIndex = 98;

            setTimeout(() => {
                oldImage.classList.remove("visible");
                newImage.style.zIndex = '';
            }, 1000);
            
            currentImage = newImage; // Set new image as current image
            setTimeout(() => {
            imagesContainer.classList.remove('transitioning');
            }, 1050);
        })
    });

}