function initRibbon() {

    if(!document.querySelector('.ribbon')) return;

    var banner = document.querySelector('.ribbon');
    var close = banner.querySelector('.close');
    var cookieName = 'ribbon_closed'; 

    addEventListener("resize", (event) => {
        if(getCookie(cookieName) != "true") {
            gsap.to(".ribbon", {
                y: getComputedStyle(banner).bottom,
                ease: "Power1.easeOut",
            }).delay(0.5);
        }    
    });

    if(getCookie(cookieName) != "true") {
        gsap.to(".ribbon", {
            y: getComputedStyle(banner).bottom,
            ease: "Power1.easeOut",
        }).delay(0.5);
    }

    close.addEventListener('click', function () {
        setCookie(cookieName, 'true', 30);
        gsap.to(".ribbon", {
            y: banner.clientHeight,
            ease: "Power1.easeOut",
        });
    })
}


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}